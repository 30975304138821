/**********************************************************************/
.login .login-thumbnail {
    width: 195px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    text-align: center; 
    border-radius: 8px;
  }
  .login .login-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border-radius: 8px;
  }
  .login .login-info {
    background-color: #fff;
    padding: 24px 1rem;
    border-radius: 10px;
    margin-bottom: 24px;
  }
  .login-header{
    padding-top: 2rem;
    padding-bottom: 5rem;
    color: #fff;
    font-weight: bold;
  }
  .login .modal-level-title { 
    margin-bottom: 24px; 
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
  
  }
  
  .login .login-thumbnail2 {
    width: 107px;
    height: 75px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;  
  }
  .login .login-thumbnail2 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center; 
  }
  
  .login-title{
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(33, 37, 41, 1);
  }
  .login-title-green{
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: rgb(36, 168, 109);
  }
  .react-custom-flag-select__select__wrapper.react-custom-flag-select__disabled div {
    cursor: auto !important;
  }
  
  body{
    padding-right: 0px !important;
    background: rgb(216,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 25%, rgba(216,0,0,1) 75%, rgba(245,201,104,1) 100%);
    min-height: 100vh;
  }
  /************************************************************************/